import { createContext, useContext } from 'react';

export interface Config {
  legacy: boolean;
  loading: boolean;
}

export const ConfigContext = createContext<Config>({
  legacy: true,
  loading: false,
});

export const useConfig = () => useContext(ConfigContext);
